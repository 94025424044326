import * as Sentry from '@sentry/browser'

const release = import.meta.env.VITE_SENTRY_RELEASE
const environment = import.meta.env.VITE_SENTRY_ENVIRONMENT

if (release) {
	Sentry.init({
		dsn: 'https://e4b939c685518e329800539d97b2a32d@sentry.itx.de/7',
		release: release,
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.browserProfilingIntegration(),
			Sentry.thirdPartyErrorFilterIntegration({
				filterKeys: ['bwegt-typo3'],
				behaviour: 'drop-error-if-contains-third-party-frames',
			}),
		],

		environment: environment ?? 'production',

		tracesSampleRate: 0.25,
	})
}
